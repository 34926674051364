import * as React from 'react'
import { useRouter } from 'next/router'
import { Spacing } from '@therich-team/design-system'

import Header from './header-layout'
import NavigationLayout from './navigation-layout'
import Footer from './footer-layout'
import StyledLayout from './styled'
import NoticeView from './header-layout/notices'

const withoutLayoutPaths = [
  '/mbti-test/',
  '/ie-guide',
  '/events/',
  '/the-rich-game',
  '/portfolio-generator',
  '/mailing-group',
  '/trading-fee-calculator',
  '/loan-interest-calculator',
  '/risk-tolerance-assessment',
  '/professional-investor-badge',
  '/professional-investor-application',
  '/badge-system',
  '/ebest',
]

const without404LayoutPaths = ['/ebest']

const hasLayoutPathRegex = [/\/events\/.+\/result$/] // withoutLayoutPaths 보다 먼저 검증되야하는 path일 경우 사용

type Props = {
  children: JSX.Element | JSX.Element[]
}

export default function Layout({ children }: Props) {
  const { asPath, pathname, route } = useRouter()

  const hasNoLayout = React.useMemo(() => {
    const hasLayoutPath = hasLayoutPathRegex.some((regex) => !!pathname.match(regex))
    if (hasLayoutPath) return false

    const hasNo404Layout =
      pathname === '/404' && without404LayoutPaths.some((path) => asPath.startsWith(path))

    if (hasNo404Layout) {
      return true
    }

    return withoutLayoutPaths.some((path) => pathname.startsWith(path))
  }, [asPath, pathname])

  return hasNoLayout ? (
    <>{children}</>
  ) : (
    <StyledLayout>
      <Header />
      <NavigationLayout />
      <main>
        {route === '/' && (
          <>
            <Spacing y="500" />
            <NoticeView />
          </>
        )}
        <Spacing y="500" />
        {children}
      </main>
      <Footer />
    </StyledLayout>
  )
}
