import * as React from 'react'
import type { OverlayScrollbarsComponentProps } from 'overlayscrollbars-react'
import dynamic from 'next/dynamic'

import 'overlayscrollbars/css/OverlayScrollbars.min.css'

const OverlayScrollbarsComponent = dynamic<OverlayScrollbarsComponentProps>(() =>
  import('overlayscrollbars-react').then((mod) => mod.OverlayScrollbarsComponent),
)

type Props = {
  children: React.ReactNode
  inheritHeight?: boolean
  className?: string
}

const Scrollbar = (props: Props) => {
  const { children, inheritHeight = false, className = '' } = props
  const style = React.useMemo(() => (inheritHeight ? { height: '100%' } : {}), [inheritHeight])

  return (
    <OverlayScrollbarsComponent
      style={style}
      className={className}
      options={{ scrollbars: { autoHide: 'scroll' } }}>
      {children}
    </OverlayScrollbarsComponent>
  )
}

Scrollbar.displayName = 'Scrollbar'

export default React.memo(Scrollbar)
