import styled from 'styled-components'

const StyledAssetSearchResult = styled.li`
  :not(:first-child) {
    margin-top: var(--spacing-legacy-20);
  }

  button {
    width: 100%;
    text-align: left;
  }

  a {
    padding: var(--spacing-legacy-4) var(--spacing-legacy-10);
  }

  button,
  a {
    display: flex;
    align-items: center;

    :disabled {
      opacity: 0.5;
    }

    .asset-image {
      flex-shrink: 0;
      display: inline-block;
      width: var(--spacing-legacy-35);
      height: var(--spacing-legacy-35);
      margin-right: var(--spacing-legacy-15);
    }

    .mark {
      width: calc(100% - var(--spacing-legacy-80) - var(--spacing-legacy-50));

      .name {
        font-size: var(--font-size-14);
      }

      .ticker {
        margin-top: var(--spacing-legacy-5);
      }
    }

    .country {
      flex-shrink: 0;
      margin-left: auto;
    }
  }
`

export default StyledAssetSearchResult
