import * as React from 'react'
import dynamic from 'next/dynamic'
import { Body } from '@therich-team/design-system'

import { useMyCommunityNotificationsWithLanguageQuery } from '@/gql/generated-api1'
import Image from '@/components/common/image'
import Link, { ExternalLink } from '@/components/common/link'
import { LocaleEnum } from '@/types/locale'
import ClientOnly from '@/components/common/client-only'
import useLanguage from '@/utils/language/language-hook'
import { useAuthTokenStore } from '@/service/auth/store'
import { ACCOUNT_PATH } from '@/route/account'

import Search from './search'
import StyledHeader from './styled'

const CommunityNotifications = dynamic(() => import('./community-notifications'))

const Header = () => {
  const { token } = useAuthTokenStore()
  const { t, language } = useLanguage('header')
  const [notification, setNotification] = React.useState(false)
  const { data } = useMyCommunityNotificationsWithLanguageQuery({
    variables: { language },
    skip: !token,
  })

  const isAlarmOn = React.useMemo(() => {
    return !!data?.myCommunityNotifications?.hasUnconfirmedNotification
  }, [data?.myCommunityNotifications?.hasUnconfirmedNotification])

  return (
    <StyledHeader>
      <div className="top-container">
        <div className="--flex-x-y-between">
          <Link className="logo" href="/" gaCategory="HEADER" gaAction="HEADER_LOGO_CLICK">
            <Image
              src="/images/TheRich_Web_Logo2.png"
              width={170}
              height={58}
              alt="The Rich"
              priority
            />
          </Link>
          <nav className="--flex-x-center">
            {/* {language === LocaleEnum.ko && (
              <ExternalLink
                className="--desktop-only"
                href="/cert"
                target="_blank"
                gaCategory="HEADER"
                gaAction="HEADER_CERTIFICATION_CLICK">
                <Body size={3} weight="Regular">
                  인증서 연동
                </Body>
              </ExternalLink>
            )} */}
            <ExternalLink
              href="/mailing-group?name=THE_RICH_INSIGHT"
              target="_blank"
              gaCategory="HEADER"
              gaAction="HEADER_NEWSLETTER_CLICK">
              <Body size={3} weight="Regular">
                {t('newsletter')}
              </Body>
            </ExternalLink>
            <ClientOnly>
              {token ? (
                <>
                  <Link href={ACCOUNT_PATH} gaCategory="HEADER" gaAction="HEADER_ACCOUNT_CLICK">
                    <Body size={3} weight="Regular">
                      {t('account')}
                    </Body>
                  </Link>
                  <button type="button" onClick={() => setNotification(true)}>
                    <Image
                      src={isAlarmOn ? '/images/icons/alarm-on.svg' : '/images/icons/alarm.svg'}
                      alt="check notifications"
                      layout="fixed"
                      width={24}
                      height={24}
                    />
                  </button>
                </>
              ) : (
                <>
                  <Link href={'/sign-in'} gaCategory="HEADER" gaAction="HEADER_SIGNIN_CLICK">
                    <Body size={3} weight="Regular">
                      {t('signIn')}
                    </Body>
                  </Link>
                  <Link href={'/sign-up'} gaCategory="HEADER" gaAction="HEADER_SIGNUP_CLICK">
                    <Body size={3} weight="Regular">
                      {t('signUp')}
                    </Body>
                  </Link>
                </>
              )}
            </ClientOnly>
          </nav>
        </div>
        <Search />
      </div>
      {notification && <CommunityNotifications isOpen onClose={() => setNotification(false)} />}
    </StyledHeader>
  )
}

export default React.memo(Header)
