import * as React from 'react'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import { useRouter } from 'next/router'

import sendGaEvent, { SendGaEventProps } from '@/service/event-log/gtag-custom-event'

type PropsWithLinkProps = NextLinkProps & {
  target?: '_blank'
  children: React.ReactNode[] | React.ReactNode | JSX.Element
  className?: string
  exact?: boolean
  isActive?: boolean
  activePaths?: string[] // exact ? activePaths 전체 비교 : activePaths 시작부분만 비교
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void
}

export type LinkProps = PropsWithLinkProps & Partial<SendGaEventProps>

// GA 이벤트 트래킹이 내장된 내부 라우터 이동
const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(({ children, ...props }, ref) => {
  const { asPath } = useRouter()
  const { gaCategory, gaAction, gaLabel, gaValue, gaNonInteraction, ...restProps } = props
  const {
    onClick,
    className,
    exact = false,
    isActive,
    activePaths,
    target,
    ...linkProps
  } = restProps

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (gaCategory && gaAction) {
        sendGaEvent({ gaCategory, gaAction, gaLabel, gaValue, gaNonInteraction })
      }

      onClick && onClick(e)
    },
    [onClick, gaAction, gaCategory, gaLabel, gaNonInteraction, gaValue],
  )

  const getClassName = React.useCallback(
    (_isActive: boolean) => {
      return [_isActive || isActive ? 'active' : '', className ?? ''].join(' ').trim()
    },
    [className, isActive],
  )

  const _className = React.useMemo(() => {
    if (activePaths) {
      const _isActive =
        isActive ||
        !!activePaths.find((path) => (exact ? asPath === path : asPath.startsWith(path)))
      if (_isActive) {
        return getClassName(_isActive)
      }
    }

    const _isActive = exact ? asPath === props.href : asPath.startsWith(props.href?.toString())
    return getClassName(_isActive)
  }, [activePaths, exact, props.href, asPath, getClassName, isActive])

  return (
    // https://nextjs.org/docs/api-reference/next/link
    // prefetch: default는 true인데 true일 경우 해당 링크 페이지에 필요한 리소스를 다운로드한다. false 일 경우 hover시 가져옴.
    <NextLink {...linkProps} passHref prefetch={false}>
      <a ref={ref} href={'//'} className={_className} onClick={handleClick} target={target}>
        {children}
      </a>
    </NextLink>
  )
})

Link.displayName = 'Link'

type ExternalLinkProps = {
  children: React.ReactNode[] | React.ReactNode | JSX.Element
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

export const ExternalLink = ({
  children,
  onClick,
  ...props
}: ExternalLinkProps & Partial<SendGaEventProps>) => {
  const { gaCategory, gaAction, gaLabel, gaValue, gaNonInteraction, ...linkProps } = props

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (gaCategory && gaAction) {
        sendGaEvent({ gaCategory, gaAction, gaLabel, gaValue, gaNonInteraction })
      }
      onClick && onClick(e)
    },
    [gaAction, gaCategory, gaLabel, gaNonInteraction, gaValue, onClick],
  )

  const rel = React.useMemo(
    () => (props.target === '_blank' ? 'noopener noreferrer nofollow' : props.rel),
    [props.target, props.rel],
  )

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <a rel={rel} target="_blank" {...linkProps} onClick={handleClick}>
      {children}
    </a>
  )
}

export default Link
