import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Body } from '@therich-team/design-system'

const FooterCompany = () => {
  const { t } = useTranslation('footer')

  return (
    <address>
      <Body size={1} weight="Regular" className="highlight-text title">
        {t('companyName')}
      </Body>
      <Body size={3} weight="Regular" className="normal-text">
        {t('companyInfo1')}
      </Body>
      <Body size={3} weight="Regular" className="normal-text">
        {t('companyInfo2')}
      </Body>
    </address>
  )
}

export default FooterCompany
