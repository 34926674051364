import React from 'react'
import { Body } from '@therich-team/design-system'

import Link from '@/components/common/link'
import Image from '@/components/common/image'
import { useIsKoreanLocale } from '@/utils/language'
import { useNoticesQuery } from '@/gql/generated-api1'

import StyledNotices from './styled'

const NoticeView = () => {
  const { data } = useNoticesQuery({ variables: { page: 0, limit: 1 } })
  const isKoreanLocale = useIsKoreanLocale()
  const latestNotice = data?.notices?.[0]
  const localizedTitle = isKoreanLocale ? latestNotice?.titleKo : latestNotice?.titleEn
  const date = (latestNotice?.createdAt || '').split(' ')[0]
  const localizedDateString = new Date(date).toLocaleDateString()

  return (
    <StyledNotices>
      {latestNotice && (
        <Link
          className="--flex-x-center"
          href="/notices"
          gaCategory="HEADER"
          gaAction="HEADER_NOTICE_CLICK"
          gaLabel={latestNotice?.titleKo}>
          <div className="--flex-shrink-off image">
            <Image src="/images/icons/alarm-primary.svg" alt="bell" width={20} height={20} />
          </div>
          <Body size={2} weight="Regular" className="--truncate title">
            {localizedTitle}
          </Body>
          <Body size={3} weight="Regular" className="--flex-shrink-off date">
            {localizedDateString}
          </Body>
        </Link>
      )}
    </StyledNotices>
  )
}

export default React.memo(NoticeView)
