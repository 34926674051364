import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const StyledHeader = styled.header`
  .top-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2.5rem 0;

    @media (max-width: 767px) {
      display: block;
      padding: ${getSize(5)};
      padding-top: ${getSize(10)};
    }

    > div {
      width: 100%;

      .logo {
        width: 17rem;
        transform: translateX(-1rem);

        @media (max-width: 767px) {
          width: ${getSize(170)};
          transform: translateX(0);
        }
      }

      nav {
        button,
        a {
          padding: 0;
          border-radius: var(--border-radius-6);
          margin-left: var(--spacing-legacy-20);

          @media (max-width: 767px) {
            margin-left: var(--spacing-legacy-15);

            :last-child {
              margin-right: var(--spacing-legacy-10);
            }
          }
        }
      }
    }
  }
`

export default StyledHeader
