import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const StyledSearchSuggestion = styled.div`
  position: absolute;
  background-color: var(--color-gray-27);
  box-shadow: var(--shadow-side);
  top: 100%;
  left: 0;
  overflow-y: auto;
  padding: var(--spacing-legacy-10) 0;
  width: 41.2rem;
  font-size: var(--font-size-15);
  margin-top: var(--spacing-legacy-8);

  @media (max-width: 767px) {
    width: ${getSize(320)};
    max-height: 50vh;
  }

  .scroll {
    max-height: 50rem;
  }

  > nav {
    margin-bottom: var(--spacing-legacy-10);
    background-color: var(--color-gray-27);
    button {
      width: 50%;
      padding: var(--spacing-legacy-15) 0;
      border-bottom: 1px solid var(--color-gray-102);
      transition: border-bottom-color 0.2s, color 0.2s;
      color: var(--color-gray-102);

      @media (hover: hover) and (pointer: fine) {
        :hover {
          color: inherit;
        }
      }

      &.active {
        color: inherit;
        border-bottom-color: var(--color-primary);
      }
    }
  }

  .error {
    color: red;
    padding: var(--spacing-legacy-10) 0;
  }

  .no-result {
    margin: var(--spacing-legacy-20) 0;

    + button {
      display: block;
      width: 80%;
      margin: auto;
      padding: var(--spacing-legacy-10);
      border-radius: 6px;
      background-color: var(--color-gray-32);
      font-size: var(--font-size-15);

      :hover {
        color: white;
      }
    }
  }
`

export default StyledSearchSuggestion
