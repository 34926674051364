import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Body } from '@therich-team/design-system'

import Link from '@/components/common/link'
import { MY_PORTFOLIOS_PATH } from '@/route/my/portfolios'
import { CHATS_PATH } from '@/route/chats'

import StyledNavigationLayout from './styled'

const communityActivePaths = ['/community']

const NavigationLayout = () => {
  const { t } = useTranslation('header')

  return (
    <StyledNavigationLayout>
      <nav>
        <Link
          href="/my/favorite-item-categories"
          gaCategory="HEADER"
          gaAction="HEADER_FAVORITES_CLICK">
          <Body size={2}>{t('favorites')}</Body>
        </Link>
        <Link href={MY_PORTFOLIOS_PATH} gaCategory="HEADER" gaAction="HEADER_PORTFOLIO_CLICK">
          <Body size={2}>{t('portfolios')}</Body>
        </Link>
        <Link href="/backtesting" gaCategory="HEADER" gaAction="HEADER_BACKTESTING_CLICK">
          <Body size={2}>{t('backtesting')}</Body>
        </Link>
        <Link
          href="/community/articles"
          gaCategory="HEADER"
          gaAction="HEADER_COMMUNITY_CLICK"
          activePaths={communityActivePaths}>
          <Body size={2}>{t('community')}</Body>
        </Link>
        <Link href={CHATS_PATH} gaCategory="HEADER" gaAction="HEADER_CHATS_CLICK" shallow>
          <Body size={2}>{t('chats')}</Body>
        </Link>
      </nav>
    </StyledNavigationLayout>
  )
}

export default React.memo(NavigationLayout)
