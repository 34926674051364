import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Body } from '@therich-team/design-system'

import { ExternalLink } from '@/components/common/link'

const FooterContact = () => {
  const { t } = useTranslation('footer')

  return (
    <div className="contact">
      <address>
        <Body as="h3" size={3} className="highlight-text title">
          {t('recruitment')}
        </Body>
        <ExternalLink
          href="https://bit.ly/TheRich-Recruit-Developer"
          target="_blank"
          rel="noopener noreferrer"
          gaCategory="FOOTER"
          gaAction="FOOTER_RECRUITMENT_CLICK">
          <Body size={3} className="normal-text">
            {t('goToRecruitmentPage')}
          </Body>
        </ExternalLink>
      </address>
      <address>
        <Body as="h3" size={3} className="highlight-text title">
          {t('customerServiceCenter')}
        </Body>
        <ExternalLink
          href="mailto:cs@therich.io"
          gaCategory="FOOTER"
          gaAction="FOOTER_CONTACT_CLICK"
          gaLabel="FOOTER_CONTACT_CS">
          <Body size={3} className="normal-text">
            {t('customerInquiry')} : cs@therich.io
          </Body>
        </ExternalLink>
        <div className="--flex-y-between admin">
          <ExternalLink
            href="mailto:admin@therich.io"
            gaCategory="FOOTER"
            gaAction="FOOTER_CONTACT_CLICK"
            gaLabel="FOOTER_CONTACT_ADMIN">
            <Body as="span" size={3} className="normal-text">
              {t('partnershipInquiry')} : admin@therich.io
            </Body>
          </ExternalLink>
        </div>
      </address>
    </div>
  )
}

export default FooterContact
