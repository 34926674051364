import type { CustomEventLogCategory, CustomEventLogCategoryWithWebview } from '@/types/event-log'
import { IS_DEVELOPMENT } from '@/constants/env'

import { isApp } from '../news-letter/webview-router'

import sendGaEvent from './gtag-event'

export type SendGaEventProps = {
  gaCategory: CustomEventLogCategory
  gaAction: `${CustomEventLogCategory}_${string}`
  gaLabel?: string
  gaValue?: number
  gaNonInteraction?: boolean
}

type SendGaEventPropsWithWebview = Omit<SendGaEventProps, 'gaCategory' | 'gaAction'> & {
  gaCategory: CustomEventLogCategoryWithWebview
  gaAction: `${CustomEventLogCategory}_${string}` | `WEBVIEW_${CustomEventLogCategory}_${string}`
}

const WEBVIEW_PREFIX = 'WEBVIEW'

/**
 * webview인 경우 prefix 붙여줌
 */
function webviewPrefix(sendGaEventProps: SendGaEventProps): SendGaEventPropsWithWebview {
  if (isApp()) {
    return {
      ...sendGaEventProps,
      gaCategory: `${WEBVIEW_PREFIX}_${sendGaEventProps.gaCategory}`,
      gaAction: `${WEBVIEW_PREFIX}_${sendGaEventProps.gaAction}`,
      gaLabel: sendGaEventProps.gaLabel && `${WEBVIEW_PREFIX}_${sendGaEventProps.gaLabel}`,
    }
  }
  return sendGaEventProps
}

export default function sendGaCustomEvent(props: SendGaEventProps): void {
  if (IS_DEVELOPMENT) {
    console.log('GA EVENT :>> ', webviewPrefix(props))
  }

  if (typeof window.gtag === 'function') {
    const {
      gaCategory,
      gaAction,
      gaLabel,
      gaValue,
      gaNonInteraction = false,
    } = webviewPrefix(props)

    sendGaEvent(gaAction, {
      event_category: gaCategory,
      event_label: gaLabel,
      value: gaValue,
      non_interaction: gaNonInteraction,
    })
  }
}
