import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Body } from '@therich-team/design-system'

import sendGaEvent from '@/service/event-log/gtag-custom-event'
import { setLocale } from '@/service/locale/storage'
import { ExternalLink } from '@/components/common/link'
import { LocaleEnum } from '@/types/locale'
import useLanguage from '@/utils/language/language-hook'

const FooterTerms = () => {
  const { t, i18n } = useTranslation('footer')
  const { isKorean } = useLanguage()

  const handleLanguageChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const locale = e.currentTarget.value
      sendGaEvent({
        gaCategory: 'FOOTER',
        gaAction: 'FOOTER_LANGUAGE_CLICK',
        gaLabel: `FOOTER_LANGUAGE_${locale.toUpperCase()}`,
      })
      setLocale({ locale })
      i18n.changeLanguage(locale)
    },
    [i18n],
  )

  return (
    <div className="terms">
      <ExternalLink
        href={
          isKorean
            ? 'https://therichteam.notion.site/becef5d9a46a471aa1928841eb3dcb86'
            : 'https://therichteam.notion.site/Terms-and-conditions-8e919a01026b49b58213e24bb7fb071c'
        }
        target="_blank"
        className="bold"
        gaCategory="FOOTER"
        gaAction="FOOTER_TERM_CLICK"
        gaLabel="FOOTER_TERM_TERMS-AND-CONDITION">
        <Body size={3}>{t('terms')}</Body>
      </ExternalLink>
      <ExternalLink
        href={
          isKorean
            ? 'https://therichteam.notion.site/d0d2c61deddd4353b31a747e13c60227'
            : 'https://therichteam.notion.site/Privacy-c442bf03aebe4016a724d8f939b77166'
        }
        target="_blank"
        className="bold"
        gaCategory="FOOTER"
        gaAction="FOOTER_TERM_CLICK"
        gaLabel="FOOTER_TERM_PRIVACY">
        <Body size={3}>{t('privacy')}</Body>
      </ExternalLink>
      <div className="languages">
        <label>
          <input
            className="--blind"
            type="radio"
            name="language"
            value={LocaleEnum.ko}
            checked={i18n.language === LocaleEnum.ko}
            onChange={handleLanguageChange}
          />
          <Body as="span" size={3}>
            한국어
          </Body>
        </label>
        <span>{` | `}</span>
        <label>
          <input
            className="--blind"
            type="radio"
            name="language"
            value={LocaleEnum.en}
            checked={i18n.language === LocaleEnum.en}
            onChange={handleLanguageChange}
          />
          <Body as="span" size={3}>
            English
          </Body>
        </label>
      </div>
    </div>
  )
}

export default FooterTerms
