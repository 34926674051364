import * as React from 'react'

import Image from '@/components/common/image'
import { ExternalLink } from '@/components/common/link'

const FooterSocial = () => {
  return (
    <ul className="social">
      <li>
        <ExternalLink
          href="https://www.facebook.com/The-Rich-206816523534788/"
          target="_blank"
          rel="noopener noreferrer"
          gaCategory="FOOTER"
          gaAction="FOOTER_SNS_CLICK"
          gaLabel="FOOTER_SNS_FACEBOOK">
          <Image src="/images/facebook.png" alt="facebook" layout="fixed" width={40} height={40} />
        </ExternalLink>
      </li>
      <li>
        <ExternalLink
          href="https://medium.com/@billionairesCorp"
          target="_blank"
          rel="noopener noreferrer"
          gaCategory="FOOTER"
          gaAction="FOOTER_SNS_CLICK"
          gaLabel="FOOTER_SNS_MEDIUM">
          <Image src="/images/medium.png" alt="medium" layout="fixed" width={40} height={40} />
        </ExternalLink>
      </li>
      <li>
        <ExternalLink
          href="https://www.youtube.com/channel/UCrL31xixqqNgCAWMnlDflCQ"
          target="_blank"
          rel="noopener noreferrer"
          gaCategory="FOOTER"
          gaAction="FOOTER_SNS_CLICK"
          gaLabel="FOOTER_SNS_YOUTUBE">
          <Image src="/images/youtube.png" alt="youtube" layout="fixed" width={40} height={40} />
        </ExternalLink>
      </li>
      <li>
        <ExternalLink
          href="https://blog.naver.com/therichio"
          target="_blank"
          rel="noopener noreferrer"
          gaCategory="FOOTER"
          gaAction="FOOTER_SNS_CLICK"
          gaLabel="FOOTER_SNS_NAVER-BLOG">
          <Image
            src="/images/naver-blog-logo.png"
            alt="youtube"
            layout="fixed"
            width={40}
            height={40}
          />
        </ExternalLink>
      </li>
    </ul>
  )
}

export default FooterSocial
