import * as React from 'react'

import type { SearchCompanyFieldsFragment } from '@/gql/generated-api1'
import Loading from '@/components/common/loading'
import Scrollbar from '@/components/common/scrollbar'
import useLanguage from '@/utils/language/language-hook'
import AssetSearchResult from '@/components/shape/asset-search/asset-search-result'

import StyledSearchSuggestion from './styled'

type Props = {
  keyword: string | undefined
  loading: boolean
  assets: SearchCompanyFieldsFragment[]
  errorMessage: string | undefined
  reportSupportAsset: () => void
}

const SearchSuggestion = ({
  keyword,
  loading,
  assets,
  errorMessage,
  reportSupportAsset,
}: Props) => {
  const { t } = useLanguage('header')
  const [categoryType, setCategoryType] = React.useState<'CRYPTO_CURRENCY' | 'COMPANY'>('COMPANY')
  const hasBothType = React.useMemo(() => {
    let hasCompany = false
    let hasCrypto = false
    return assets.some((asset) => {
      if (asset.categoryType === 'COMPANY') {
        hasCompany = true
      } else if (asset.categoryType === 'CRYPTO_CURRENCY') {
        hasCrypto = true
      }
      return hasCompany && hasCrypto
    })
  }, [assets])
  const filteredAssets = React.useMemo(
    () => (hasBothType ? assets.filter((asset) => asset.categoryType === categoryType) : assets),
    [assets, categoryType, hasBothType],
  )

  if (loading) {
    return (
      <StyledSearchSuggestion>
        <Loading minHeight={80} />
      </StyledSearchSuggestion>
    )
  }

  if (errorMessage) {
    return (
      <StyledSearchSuggestion>
        <p className="error">Oooooops!</p>
        <p className="error">{errorMessage}</p>
      </StyledSearchSuggestion>
    )
  }

  if (!assets.length && keyword) {
    return (
      <StyledSearchSuggestion>
        <p className="no-result">{t('search.no')}</p>
        <button onClick={reportSupportAsset}>{t('search.report', { keyword })}</button>
      </StyledSearchSuggestion>
    )
  }

  return keyword ? (
    <StyledSearchSuggestion>
      {hasBothType && (
        <nav className="--flex-x-y-between">
          <button
            type="button"
            className={categoryType === 'COMPANY' ? 'active' : ''}
            onClick={() => setCategoryType('COMPANY')}>
            {t('common:stock')}
          </button>
          <button
            type="button"
            className={categoryType === 'CRYPTO_CURRENCY' ? 'active' : ''}
            onClick={() => setCategoryType('CRYPTO_CURRENCY')}>
            {t('common:coin')}
          </button>
        </nav>
      )}
      <Scrollbar className="scroll">
        <ul>
          {filteredAssets.map((asset) => (
            <React.Fragment key={`${asset.categoryType}-${asset.id}`}>
              {asset.ticker && <AssetSearchResult {...asset} />}
            </React.Fragment>
          ))}
        </ul>
      </Scrollbar>
    </StyledSearchSuggestion>
  ) : null
}

export default SearchSuggestion
