import { IS_DEVELOPMENT } from '@/constants/env'

// react native app 환경인지 판단
export const isApp = () => {
  return typeof window !== 'undefined' && !!window.ReactNativeWebView
}

export const sendRouterEvent = ({ path }: { path: string }): void => {
  window?.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'ROUTER_EVENT', data: path }))
}

export const sendPostMessage = (
  messages: {
    type: 'NAVIGATION_HEADER' | 'DEEP_LINK'
    params: Record<string, unknown> | Record<string, unknown>[]
  }[],
): void => {
  if (IS_DEVELOPMENT) {
    console.log('messages :>> ', messages)
    return
  }

  if (window?.ReactNativeWebView?.postMessage) {
    window.ReactNativeWebView.postMessage(JSON.stringify(messages))
  }
}

// 뉴스레터 뒤로가기 및 확인 버튼 클릭시
export const onWebViewRouterBack = () => {
  if (isApp()) sendRouterEvent({ path: 'back' })
}
