export const ACCOUNT_PATH = '/account'

export type AccountMenu = 'notifications'
export type AccountMode = 'edit'
export type AccountNotification = 'newsKeywords'
export type AccountType = 'posts' | 'favorites' | 'portfolios'
export type AccountPostsType = 'articles' | 'comments' | 'bookmarks'

export const accountPath = ({
  basePath = ACCOUNT_PATH, // user, account path 겸용
  ...parameters
}: {
  basePath?: string
  menu?: AccountMenu
  mode?: AccountMode
  type?: AccountType
  postsType?: AccountPostsType
  notification?: AccountNotification
} = {}) => {
  const searchParams = new URLSearchParams()
  Object.entries(parameters).forEach(([key, value]) => {
    if ((value ?? null) !== null) {
      searchParams.set(key, value)
    }
  })
  const params = searchParams.toString()
  return params ? `${basePath}?${params}` : basePath
}

export const accountNotificationSetting = accountPath({ menu: 'notifications' })

export const accountNewsKeywordsNotiSetting = accountPath({
  menu: 'notifications',
  notification: 'newsKeywords',
})
