import { CurrencySymbolEnum } from '@/types/currency'
import { LocaleEnum } from '@/types/locale'

type Props = {
  base: string
  locale: LocaleEnum
}

export default function cryptoDetailPagePath({ base, locale }: Props) {
  const counter = locale === LocaleEnum.ko ? CurrencySymbolEnum.KRW : CurrencySymbolEnum.USD
  return `/crypto?base=${base}&counter=${counter}`
}
