import * as React from 'react'
import { useRouter } from 'next/router'
import { useTranslation, getI18n } from 'react-i18next'

import { LocaleEnum, KoreanEnum } from '@/types/locale'

const koreans = Object.values(KoreanEnum)

export const useServerLocale = (): LocaleEnum => {
  const { locale } = useRouter()
  return locale === LocaleEnum.ko ? LocaleEnum.ko : LocaleEnum.en
}

export const useIsKoreanLocale = (): boolean => {
  const { i18n } = useTranslation()
  return koreans.includes(i18n.language as KoreanEnum)
}

export const getIsLanguageKorean = (): boolean => {
  return koreans.includes(getI18n().language as KoreanEnum)
}

type NameProps = { [key in LocaleEnum]?: string | null }

export const useGetByLocaleName = (): (({ en, ko }: NameProps) => string) => {
  const { i18n } = useTranslation()

  return React.useCallback(
    ({ en, ko }) => {
      const locleStr = i18n.language === LocaleEnum.ko ? ko || en : en || ko
      return locleStr || ''
    },
    [i18n.language],
  )
}

export const useLocaleName = ({ en, ko }: NameProps): string => {
  const getByLocaleName = useGetByLocaleName()
  return React.useMemo(() => getByLocaleName({ en, ko }), [getByLocaleName, en, ko])
}
