import type { CustomEventLogCategoryWithWebview } from '@/types/event-log'

type CustomEvent = {
  event_category: CustomEventLogCategoryWithWebview
  event_label?: string
  value?: number
  non_interaction: boolean
}

type SearchEvent = {
  search_term: string
}

type Props = CustomEvent | SearchEvent

export default function sendGa(event: string, props: Props): void {
  if (process.env.NEXT_PUBLIC_VERCEL_ENV === 'production') {
    window.gtag('event', event, props)
  }
}

/**
표준 이벤트(권장)
다양한 플랫폼에서 활용가능하도록 고안된 표준 이벤트와 표준 매개변수가 존재
https://developers.google.com/gtagjs/reference/event
https://developers.google.com/analytics/devguides/collection/gtagjs/events#default_google_analytics_events

커스텀 이벤트
https://developers.google.com/analytics/devguides/collection/gtagjs/events#send_events

 */
