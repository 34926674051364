import * as React from 'react'

import ClientOnly from '@/components/common/client-only'

import StyledFooter from './styled'
import FooterCompany from './footer-company'
import FooterTerms from './footer-terms'
import FooterSocial from './footer-social'
import FooterContact from './footer-contact'
import FooterMobileApp from './footer-mobile-app'

function Footer() {
  return (
    <StyledFooter>
      <hr />
      <div className="--flex-y-between container">
        <div className="contents">
          <FooterCompany />
          <FooterTerms />
        </div>
        <div className="contents">
          {/* 운영서버에서 SSR시와 CSR시 불일치 현상으로 client only */}
          <ClientOnly>
            <FooterContact />
          </ClientOnly>
        </div>
      </div>
      <div className="--flex-y-between contents">
        <FooterSocial />
        <div className="desktop">
          <FooterMobileApp />
        </div>
      </div>
      <div className="contents mobile">
        <FooterMobileApp />
      </div>
    </StyledFooter>
  )
}

export default React.memo(Footer)
