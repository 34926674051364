import styled from 'styled-components'

import { getSizeBy375 as getSize } from '@/utils/style/size'

const StyledSearch = styled.section`
  position: relative;
  z-index: var(--z-100);

  @media (min-width: 768px) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 767px) {
    margin: 0 ${getSize(20)};
    margin-top: ${getSize(10)};
  }

  p {
    text-align: center;
    font-size: 14px;

    @media (max-width: 767px) {
      font-size: ${getSize(13)};
    }
  }

  input {
    background-color: var(--color-gray-32);
    border: 1px solid var(--color-gray-27);
    margin-top: 20px;
    padding: 10px 15px;
    width: 380px;
    font-size: 13px;
    border-radius: var(--border-radius-12);
    transition: border-color 0.2s;

    :focus {
      border: 1px solid var(--color-gray-207);
    }

    @media (max-width: 767px) {
      display: block;
      margin-top: ${getSize(10)};
      padding: ${getSize(10)};
      width: ${getSize(300)};
      font-size: ${getSize(15)};
    }
  }
`

export default StyledSearch
