import * as React from 'react'
import { useTranslation } from 'react-i18next'

import Image from '@/components/common/image'
import { ExternalLink } from '@/components/common/link'
import { LocaleEnum } from '@/types/locale'

const FooterMobileApp = () => {
  const { i18n } = useTranslation('footer')

  return (
    <div className="mobile-app">
      <ExternalLink
        href={`https://apps.apple.com/${
          i18n.language === LocaleEnum.ko ? 'kr' : 'us'
        }/app/the-rich/id1462844342`}
        gaCategory="FOOTER"
        gaAction="FOOTER_MOBILE-APP-LINK_CLICK"
        gaLabel="FOOTER_MOBILE-APP-LINK_iOS">
        <Image
          src="/images/ios_appstore_image.png"
          alt="app store"
          layout="fixed"
          width={120}
          height={42}
        />
      </ExternalLink>
      <ExternalLink
        href={`https://play.google.com/store/apps/details?id=io.therich.app`}
        gaCategory="FOOTER"
        gaAction="FOOTER_MOBILE-APP-LINK_CLICK"
        gaLabel="FOOTER_MOBILE-APP-LINK_android">
        <Image
          src="/images/android_play_store_image.png"
          alt="play store"
          layout="fixed"
          width={120}
          height={42}
        />
      </ExternalLink>
    </div>
  )
}

export default FooterMobileApp
