import styled from 'styled-components'

import { getSizeBy375 } from '@/utils/style/size'

const StyledFooter = styled.footer`
  width: 100%;
  margin: 0 auto;
  position: relative;

  @media (max-width: 767px) {
    width: 100%;
  }

  .title {
    margin-bottom: var(--spacing-legacy-20);

    @media (max-width: 767px) {
      margin-top: ${getSizeBy375(30)};
    }
  }

  .highlight-text {
    color: var(--color-gray-207);
  }

  .normal-text {
    color: var(--color-gray-154);
  }

  hr {
    border-color: var(--color-gray-128);
    width: 77px;
    margin: 40px auto;

    @media (max-width: 767px) {
      width: ${getSizeBy375(77)};
      margin: ${getSizeBy375(40)} auto;
    }
  }

  .container {
    @media (max-width: 767px) {
      flex-direction: column-reverse;
      margin-top: var(--spacing-legacy-20);
    }
  }

  .desktop {
    @media (max-width: 767px) {
      display: none;
    }
  }

  .mobile {
    display: none;
    @media (max-width: 767px) {
      display: inherit;
    }
  }

  .contents {
    @media (max-width: 767px) {
      padding: 0 20px;
    }
  }

  .terms {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: var(--spacing-legacy-20) 0px;

    .languages {
      input:checked + span {
        color: var(--color-gray-207);
      }

      span {
        cursor: pointer;
        color: var(--color-gray-75);
        font-weight: bold;
      }
    }
  }

  .social {
    margin: var(--spacing-legacy-25) 0px;

    li {
      display: inline-block;
      margin-right: var(--spacing-legacy-20);
      border-radius: var(--border-radius-12);
      overflow: hidden;
    }
  }

  .mobile-app {
    margin: var(--spacing-legacy-25) 0;
    text-align: center;

    @media (max-width: 767px) {
      margin-top: 0;
      margin-bottom: ${getSizeBy375(100)};
    }

    > *:last-child {
      margin-left: var(--spacing-legacy-20);
    }
  }

  .contact {
    @media (min-width: 768px) {
      display: flex;

      > *:not(:first-child) {
        margin-left: 5rem;
      }

      .admin {
        flex-direction: column;
        a {
          margin: var(--spacing-legacy-10) 0;
        }
      }
    }

    @media (max-width: 767px) {
      .admin {
        margin: var(--spacing-legacy-10) 0;
      }
    }
  }
`

export default StyledFooter
