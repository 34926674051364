import styled from 'styled-components'

const StyledNavigationLayout = styled.div`
  border-bottom: 1px solid var(--color-gray-39);
  min-width: var(--contents-width);

  @media (max-width: 767px) {
    margin-top: var(--spacing-legacy-10);
  }

  nav {
    margin: auto;
    width: var(--contents-width);

    @media (max-width: 767px) {
      display: flex;
      overflow-y: auto;
      ::-webkit-scrollbar {
        display: none;
      }
    }

    a {
      position: relative;
      display: inline-block;
      margin-right: var(--spacing-legacy-30);
      padding: var(--spacing-legacy-10) 0;
      padding-bottom: var(--spacing-legacy-20);
      word-break: keep-all;

      @media (max-width: 767px) {
        :first-child {
          margin-left: var(--spacing-legacy-20);
        }
      }

      @media (hover: hover) and (pointer: fine) {
        :hover {
          ::after {
            opacity: 1;
          }
        }
      }

      &.active {
        ::after {
          opacity: 1;
        }
      }

      ::after {
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        display: inline-block;
        height: 3px;
        background-color: var(--color-primary);
        opacity: 0;
        transition: opacity 0.2s, background-color 0.2s;
      }
    }
  }
`

export default StyledNavigationLayout
