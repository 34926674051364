import styled from 'styled-components'

const StyledNotices = styled.div`
  display: flex;
  min-height: var(--spacing-legacy-20);

  @media (max-width: 767px) {
    padding: 0 var(--spacing-legacy-20);
  }

  a {
    max-width: 100%;

    .image {
      width: var(--spacing-legacy-20);
      height: var(--spacing-legacy-20);
    }

    .title {
      margin-left: var(--spacing-legacy-12);
      max-width: 90%;
    }

    .date {
      color: var(--color-gray-128);
      margin-left: var(--spacing-legacy-15);
    }
  }
`

export default StyledNotices
